/* 🤖 this file was generated by svg-to-ts */

export type DexIcons =
    | 'ADD_1_F'
    | 'ADD_BOLD'
    | 'ALERT_CIRCLE'
    | 'ALERT_TRIANGLE'
    | 'ALIGN_BOTTOM'
    | 'ALIGN_CENTER'
    | 'ALIGN_LEFT'
    | 'ALIGN_MIDDLE'
    | 'ALIGN_RIGHT'
    | 'ALIGN_TOP'
    | 'ARROW_BACK'
    | 'ARROW_DOWN'
    | 'ARROW_LEFT'
    | 'ARROW_RIGHT'
    | 'ARROW_UP'
    | 'BIN'
    | 'BIN_FILLED'
    | 'BRING_FORWARD'
    | 'BRING_TO_THE_FRONT'
    | 'CHECK'
    | 'CHECK_CIRCLE'
    | 'CLIP_ART'
    | 'CLOSE'
    | 'CLOSE_CIRCLE'
    | 'CLOSE_DEX'
    | 'COLOR_BRUSH_PAINT'
    | 'COLOR_DROP_PICK'
    | 'COLOR_FILL'
    | 'COLOR_PICKER'
    | 'COLOR_PICKER_CT'
    | 'COLOR_STROKE'
    | 'CROP'
    | 'CROP_CT'
    | 'CUTLINE'
    | 'DELETE'
    | 'DUPLICATE'
    | 'DUPLICATE_ITEM'
    | 'EDIT_PATTERN'
    | 'EDIT_TEXT_AREA'
    | 'EVENTS'
    | 'FILE_UPLOAD'
    | 'FILTER'
    | 'FOLDER'
    | 'FOLDER_CT'
    | 'FONT_SIZE'
    | 'GRAPHIC'
    | 'HOLIDAYS_PANEL'
    | 'IMAGE'
    | 'IMAGES_PANEL'
    | 'INFO'
    | 'INFO_CIRCLE'
    | 'ITEM_ALIGNMENT_BOTTOM_ICON'
    | 'ITEM_ALIGNMENT_CENTER_HORIZONTALLY_ICON'
    | 'ITEM_ALIGNMENT_CENTER_VERTICALLY_ICON'
    | 'ITEM_ALIGNMENT_LEFT_ICON'
    | 'ITEM_ALIGNMENT_RIGHT_ICON'
    | 'ITEM_ALIGNMENT_TOP_ICON'
    | 'LAYOUTS_PANEL'
    | 'LINE_WEIGHT'
    | 'LIST_BULLETS'
    | 'LIST_NUMBERS'
    | 'LOCK'
    | 'LOCK_CT'
    | 'LOCK_FILLED'
    | 'LOCK_UNLOCK'
    | 'LOCK_UNLOCK_FILLED'
    | 'MOVE_BACKWARD'
    | 'MOVE_FORWARD'
    | 'MOVE_TO_BOTTOM'
    | 'MOVE_TO_TOP'
    | 'MULTI_SELECT'
    | 'OPACITY'
    | 'ORIENTATION_L'
    | 'ORIENTATION_P'
    | 'ORIENTATION_PA'
    | 'ORIENTATION_S'
    | 'PAGE_TOOLTIP'
    | 'PATTERN'
    | 'PATTERN_THEME'
    | 'PENCIL'
    | 'PHOTO_EDITOR'
    | 'PIXART_360'
    | 'PIXART_ADD'
    | 'PIXART_AI_STUDIO_TAB'
    | 'PIXART_ARROW_DOWN'
    | 'PIXART_ARROW_MAXIMIZE'
    | 'PIXART_ARROW_MINIMIZE'
    | 'PIXART_ARROW_NEXT'
    | 'PIXART_ARROW_PREV'
    | 'PIXART_ARROW_UP'
    | 'PIXART_BACKGROUND_TAB'
    | 'PIXART_BULB'
    | 'PIXART_CANCEL_ICON'
    | 'PIXART_COLOR_TOOL'
    | 'PIXART_CROP_CANCEL'
    | 'PIXART_CROP_CHECK'
    | 'PIXART_CROSS_ICON'
    | 'PIXART_DELETE_EMPTY'
    | 'PIXART_EDIT_BUTTON'
    | 'PIXART_ELEMENT_TAB'
    | 'PIXART_EMPTY_COLOR_BIG'
    | 'PIXART_EMPTY_COLOR_SMALL'
    | 'PIXART_FLIP_HORIZONTAL'
    | 'PIXART_FLIP_VERTICAL'
    | 'PIXART_FONT_FAMILY_ICON'
    | 'PIXART_FONT_SIZE_ICON'
    | 'PIXART_GEAR'
    | 'PIXART_IMAGE_TAB'
    | 'PIXART_INVERT_COLOR'
    | 'PIXART_INVERT_COLOR_REVERSE'
    | 'PIXART_MAGIC_WAND'
    | 'PIXART_MAGIC_WAND_TRANSPARENT'
    | 'PIXART_MINUS_ICON'
    | 'PIXART_MOVE'
    | 'PIXART_NOTIFY_SUCCESS'
    | 'PIXART_NOTIFY_WARNING'
    | 'PIXART_NOTIFY_WARNING_OUTLINE'
    | 'PIXART_PLUS_ICON'
    | 'PIXART_RAINBOW_CANCEL_LARGE'
    | 'PIXART_RAINBOW_CANCEL_SMALL'
    | 'PIXART_RAINBOW_PLUS_LARGE'
    | 'PIXART_RAINBOW_PLUS_SMALL'
    | 'PIXART_REFRESH_BUTTON'
    | 'PIXART_SPACING_ICON'
    | 'PIXART_TEMPLATE_TAB'
    | 'PIXART_TEXTCOLOR'
    | 'PIXART_TEXT_TAB'
    | 'PIXART_ZOOM_IN_ICON'
    | 'POSITION'
    | 'PREVIEW'
    | 'PREVIEW_HIDE'
    | 'QR_CODE'
    | 'REDO'
    | 'REDO_CT'
    | 'REGULATE'
    | 'REMOVE_BACKGROUND'
    | 'REPLACE'
    | 'REPLACE_IMAGE'
    | 'RESIZE_BOX'
    | 'RESIZE_EXPAND_SIDES'
    | 'ROTATE_BACK'
    | 'ROTATE_BACK_FILLED'
    | 'ROTATE_FORWARD'
    | 'ROTATE_FORWARD_CT'
    | 'ROTATE_FORWARD_FILLED'
    | 'SCALE_HEIGHT'
    | 'SEARCH'
    | 'SEARCH_DEX'
    | 'SEND_BACKWARD'
    | 'SEND_TO_THE_BACK'
    | 'SETTINGS'
    | 'SHAPE'
    | 'SHAPE_CIRCLE'
    | 'SHAPE_CUSTOM'
    | 'SHAPE_LOOSE'
    | 'SHAPE_MEDIUM'
    | 'SHAPE_ROUNDED'
    | 'SHAPE_SQUARE'
    | 'SHAPE_TIGHT'
    | 'SHARPEN'
    | 'SHOW_MORE'
    | 'SHUFFLE'
    | 'TEXT'
    | 'TEXT_ALIGNMENT_CENTER'
    | 'TEXT_ALIGNMENT_CENTER_CT'
    | 'TEXT_ALIGNMENT_LEFT'
    | 'TEXT_ALIGNMENT_LEFT_CT'
    | 'TEXT_ALIGNMENT_RIGHT'
    | 'TEXT_ALIGNMENT_RIGHT_CT'
    | 'TEXT_BOLD'
    | 'TEXT_BOLD_CT'
    | 'TEXT_CASING_DEFAULT'
    | 'TEXT_CASING_LOWER'
    | 'TEXT_CASING_UPPER'
    | 'TEXT_ITALIC'
    | 'TEXT_ITALIC_CT'
    | 'TEXT_LIST_BULLETS'
    | 'TEXT_LIST_NUMBERS'
    | 'TEXT_LOWERCASE'
    | 'TEXT_MIXED_CASE'
    | 'TEXT_STRIKEOUT'
    | 'TEXT_STRIKEOUT_CT'
    | 'TEXT_UNDERLINE'
    | 'TEXT_UNDERLINE_CT'
    | 'TEXT_UPPERCASE'
    | 'UNDO'
    | 'UNDO_CT'
    | 'UNLOCK'
    | 'UNSHARPEN'
    | 'UPLOAD_PHOTO'
    | 'VIEW'
    | 'ZOOM_IN'
    | 'ZOOM_OUT';
export interface DexIcon {
    name: DesignExperienceIcons;
    data: string;
}
export enum DesignExperienceIcons {
    ADD_1_F = 'ADD_1_F',
    ADD_BOLD = 'ADD_BOLD',
    ALERT_CIRCLE = 'ALERT_CIRCLE',
    ALERT_TRIANGLE = 'ALERT_TRIANGLE',
    ALIGN_BOTTOM = 'ALIGN_BOTTOM',
    ALIGN_CENTER = 'ALIGN_CENTER',
    ALIGN_LEFT = 'ALIGN_LEFT',
    ALIGN_MIDDLE = 'ALIGN_MIDDLE',
    ALIGN_RIGHT = 'ALIGN_RIGHT',
    ALIGN_TOP = 'ALIGN_TOP',
    ARROW_BACK = 'ARROW_BACK',
    ARROW_DOWN = 'ARROW_DOWN',
    ARROW_LEFT = 'ARROW_LEFT',
    ARROW_RIGHT = 'ARROW_RIGHT',
    ARROW_UP = 'ARROW_UP',
    BIN = 'BIN',
    BIN_FILLED = 'BIN_FILLED',
    BRING_FORWARD = 'BRING_FORWARD',
    BRING_TO_THE_FRONT = 'BRING_TO_THE_FRONT',
    CHECK = 'CHECK',
    CHECK_CIRCLE = 'CHECK_CIRCLE',
    CLIP_ART = 'CLIP_ART',
    CLOSE = 'CLOSE',
    CLOSE_CIRCLE = 'CLOSE_CIRCLE',
    CLOSE_DEX = 'CLOSE_DEX',
    COLOR_BRUSH_PAINT = 'COLOR_BRUSH_PAINT',
    COLOR_DROP_PICK = 'COLOR_DROP_PICK',
    COLOR_FILL = 'COLOR_FILL',
    COLOR_PICKER = 'COLOR_PICKER',
    COLOR_PICKER_CT = 'COLOR_PICKER_CT',
    COLOR_STROKE = 'COLOR_STROKE',
    CROP = 'CROP',
    CROP_CT = 'CROP_CT',
    CUTLINE = 'CUTLINE',
    DELETE = 'DELETE',
    DUPLICATE = 'DUPLICATE',
    DUPLICATE_ITEM = 'DUPLICATE_ITEM',
    EDIT_PATTERN = 'EDIT_PATTERN',
    EDIT_TEXT_AREA = 'EDIT_TEXT_AREA',
    EVENTS = 'EVENTS',
    FILE_UPLOAD = 'FILE_UPLOAD',
    FILTER = 'FILTER',
    FOLDER = 'FOLDER',
    FOLDER_CT = 'FOLDER_CT',
    FONT_SIZE = 'FONT_SIZE',
    GRAPHIC = 'GRAPHIC',
    HOLIDAYS_PANEL = 'HOLIDAYS_PANEL',
    IMAGE = 'IMAGE',
    IMAGES_PANEL = 'IMAGES_PANEL',
    INFO = 'INFO',
    INFO_CIRCLE = 'INFO_CIRCLE',
    ITEM_ALIGNMENT_BOTTOM_ICON = 'ITEM_ALIGNMENT_BOTTOM_ICON',
    ITEM_ALIGNMENT_CENTER_HORIZONTALLY_ICON = 'ITEM_ALIGNMENT_CENTER_HORIZONTALLY_ICON',
    ITEM_ALIGNMENT_CENTER_VERTICALLY_ICON = 'ITEM_ALIGNMENT_CENTER_VERTICALLY_ICON',
    ITEM_ALIGNMENT_LEFT_ICON = 'ITEM_ALIGNMENT_LEFT_ICON',
    ITEM_ALIGNMENT_RIGHT_ICON = 'ITEM_ALIGNMENT_RIGHT_ICON',
    ITEM_ALIGNMENT_TOP_ICON = 'ITEM_ALIGNMENT_TOP_ICON',
    LAYOUTS_PANEL = 'LAYOUTS_PANEL',
    LINE_WEIGHT = 'LINE_WEIGHT',
    LIST_BULLETS = 'LIST_BULLETS',
    LIST_NUMBERS = 'LIST_NUMBERS',
    LOCK = 'LOCK',
    LOCK_CT = 'LOCK_CT',
    LOCK_FILLED = 'LOCK_FILLED',
    LOCK_UNLOCK = 'LOCK_UNLOCK',
    LOCK_UNLOCK_FILLED = 'LOCK_UNLOCK_FILLED',
    MOVE_BACKWARD = 'MOVE_BACKWARD',
    MOVE_FORWARD = 'MOVE_FORWARD',
    MOVE_TO_BOTTOM = 'MOVE_TO_BOTTOM',
    MOVE_TO_TOP = 'MOVE_TO_TOP',
    MULTI_SELECT = 'MULTI_SELECT',
    OPACITY = 'OPACITY',
    ORIENTATION_L = 'ORIENTATION_L',
    ORIENTATION_P = 'ORIENTATION_P',
    ORIENTATION_PA = 'ORIENTATION_PA',
    ORIENTATION_S = 'ORIENTATION_S',
    PAGE_TOOLTIP = 'PAGE_TOOLTIP',
    PATTERN = 'PATTERN',
    PATTERN_THEME = 'PATTERN_THEME',
    PENCIL = 'PENCIL',
    PHOTO_EDITOR = 'PHOTO_EDITOR',
    PIXART_360 = 'PIXART_360',
    PIXART_ADD = 'PIXART_ADD',
    PIXART_AI_STUDIO_TAB = 'PIXART_AI_STUDIO_TAB',
    PIXART_ARROW_DOWN = 'PIXART_ARROW_DOWN',
    PIXART_ARROW_MAXIMIZE = 'PIXART_ARROW_MAXIMIZE',
    PIXART_ARROW_MINIMIZE = 'PIXART_ARROW_MINIMIZE',
    PIXART_ARROW_NEXT = 'PIXART_ARROW_NEXT',
    PIXART_ARROW_PREV = 'PIXART_ARROW_PREV',
    PIXART_ARROW_UP = 'PIXART_ARROW_UP',
    PIXART_BACKGROUND_TAB = 'PIXART_BACKGROUND_TAB',
    PIXART_BULB = 'PIXART_BULB',
    PIXART_CANCEL_ICON = 'PIXART_CANCEL_ICON',
    PIXART_COLOR_TOOL = 'PIXART_COLOR_TOOL',
    PIXART_CROP_CANCEL = 'PIXART_CROP_CANCEL',
    PIXART_CROP_CHECK = 'PIXART_CROP_CHECK',
    PIXART_CROSS_ICON = 'PIXART_CROSS_ICON',
    PIXART_DELETE_EMPTY = 'PIXART_DELETE_EMPTY',
    PIXART_EDIT_BUTTON = 'PIXART_EDIT_BUTTON',
    PIXART_ELEMENT_TAB = 'PIXART_ELEMENT_TAB',
    PIXART_EMPTY_COLOR_BIG = 'PIXART_EMPTY_COLOR_BIG',
    PIXART_EMPTY_COLOR_SMALL = 'PIXART_EMPTY_COLOR_SMALL',
    PIXART_FLIP_HORIZONTAL = 'PIXART_FLIP_HORIZONTAL',
    PIXART_FLIP_VERTICAL = 'PIXART_FLIP_VERTICAL',
    PIXART_FONT_FAMILY_ICON = 'PIXART_FONT_FAMILY_ICON',
    PIXART_FONT_SIZE_ICON = 'PIXART_FONT_SIZE_ICON',
    PIXART_GEAR = 'PIXART_GEAR',
    PIXART_IMAGE_TAB = 'PIXART_IMAGE_TAB',
    PIXART_INVERT_COLOR = 'PIXART_INVERT_COLOR',
    PIXART_INVERT_COLOR_REVERSE = 'PIXART_INVERT_COLOR_REVERSE',
    PIXART_MAGIC_WAND = 'PIXART_MAGIC_WAND',
    PIXART_MAGIC_WAND_TRANSPARENT = 'PIXART_MAGIC_WAND_TRANSPARENT',
    PIXART_MINUS_ICON = 'PIXART_MINUS_ICON',
    PIXART_MOVE = 'PIXART_MOVE',
    PIXART_NOTIFY_SUCCESS = 'PIXART_NOTIFY_SUCCESS',
    PIXART_NOTIFY_WARNING = 'PIXART_NOTIFY_WARNING',
    PIXART_NOTIFY_WARNING_OUTLINE = 'PIXART_NOTIFY_WARNING_OUTLINE',
    PIXART_PLUS_ICON = 'PIXART_PLUS_ICON',
    PIXART_RAINBOW_CANCEL_LARGE = 'PIXART_RAINBOW_CANCEL_LARGE',
    PIXART_RAINBOW_CANCEL_SMALL = 'PIXART_RAINBOW_CANCEL_SMALL',
    PIXART_RAINBOW_PLUS_LARGE = 'PIXART_RAINBOW_PLUS_LARGE',
    PIXART_RAINBOW_PLUS_SMALL = 'PIXART_RAINBOW_PLUS_SMALL',
    PIXART_REFRESH_BUTTON = 'PIXART_REFRESH_BUTTON',
    PIXART_SPACING_ICON = 'PIXART_SPACING_ICON',
    PIXART_TEMPLATE_TAB = 'PIXART_TEMPLATE_TAB',
    PIXART_TEXTCOLOR = 'PIXART_TEXTCOLOR',
    PIXART_TEXT_TAB = 'PIXART_TEXT_TAB',
    PIXART_ZOOM_IN_ICON = 'PIXART_ZOOM_IN_ICON',
    POSITION = 'POSITION',
    PREVIEW = 'PREVIEW',
    PREVIEW_HIDE = 'PREVIEW_HIDE',
    QR_CODE = 'QR_CODE',
    REDO = 'REDO',
    REDO_CT = 'REDO_CT',
    REGULATE = 'REGULATE',
    REMOVE_BACKGROUND = 'REMOVE_BACKGROUND',
    REPLACE = 'REPLACE',
    REPLACE_IMAGE = 'REPLACE_IMAGE',
    RESIZE_BOX = 'RESIZE_BOX',
    RESIZE_EXPAND_SIDES = 'RESIZE_EXPAND_SIDES',
    ROTATE_BACK = 'ROTATE_BACK',
    ROTATE_BACK_FILLED = 'ROTATE_BACK_FILLED',
    ROTATE_FORWARD = 'ROTATE_FORWARD',
    ROTATE_FORWARD_CT = 'ROTATE_FORWARD_CT',
    ROTATE_FORWARD_FILLED = 'ROTATE_FORWARD_FILLED',
    SCALE_HEIGHT = 'SCALE_HEIGHT',
    SEARCH = 'SEARCH',
    SEARCH_DEX = 'SEARCH_DEX',
    SEND_BACKWARD = 'SEND_BACKWARD',
    SEND_TO_THE_BACK = 'SEND_TO_THE_BACK',
    SETTINGS = 'SETTINGS',
    SHAPE = 'SHAPE',
    SHAPE_CIRCLE = 'SHAPE_CIRCLE',
    SHAPE_CUSTOM = 'SHAPE_CUSTOM',
    SHAPE_LOOSE = 'SHAPE_LOOSE',
    SHAPE_MEDIUM = 'SHAPE_MEDIUM',
    SHAPE_ROUNDED = 'SHAPE_ROUNDED',
    SHAPE_SQUARE = 'SHAPE_SQUARE',
    SHAPE_TIGHT = 'SHAPE_TIGHT',
    SHARPEN = 'SHARPEN',
    SHOW_MORE = 'SHOW_MORE',
    SHUFFLE = 'SHUFFLE',
    TEXT = 'TEXT',
    TEXT_ALIGNMENT_CENTER = 'TEXT_ALIGNMENT_CENTER',
    TEXT_ALIGNMENT_CENTER_CT = 'TEXT_ALIGNMENT_CENTER_CT',
    TEXT_ALIGNMENT_LEFT = 'TEXT_ALIGNMENT_LEFT',
    TEXT_ALIGNMENT_LEFT_CT = 'TEXT_ALIGNMENT_LEFT_CT',
    TEXT_ALIGNMENT_RIGHT = 'TEXT_ALIGNMENT_RIGHT',
    TEXT_ALIGNMENT_RIGHT_CT = 'TEXT_ALIGNMENT_RIGHT_CT',
    TEXT_BOLD = 'TEXT_BOLD',
    TEXT_BOLD_CT = 'TEXT_BOLD_CT',
    TEXT_CASING_DEFAULT = 'TEXT_CASING_DEFAULT',
    TEXT_CASING_LOWER = 'TEXT_CASING_LOWER',
    TEXT_CASING_UPPER = 'TEXT_CASING_UPPER',
    TEXT_ITALIC = 'TEXT_ITALIC',
    TEXT_ITALIC_CT = 'TEXT_ITALIC_CT',
    TEXT_LIST_BULLETS = 'TEXT_LIST_BULLETS',
    TEXT_LIST_NUMBERS = 'TEXT_LIST_NUMBERS',
    TEXT_LOWERCASE = 'TEXT_LOWERCASE',
    TEXT_MIXED_CASE = 'TEXT_MIXED_CASE',
    TEXT_STRIKEOUT = 'TEXT_STRIKEOUT',
    TEXT_STRIKEOUT_CT = 'TEXT_STRIKEOUT_CT',
    TEXT_UNDERLINE = 'TEXT_UNDERLINE',
    TEXT_UNDERLINE_CT = 'TEXT_UNDERLINE_CT',
    TEXT_UPPERCASE = 'TEXT_UPPERCASE',
    UNDO = 'UNDO',
    UNDO_CT = 'UNDO_CT',
    UNLOCK = 'UNLOCK',
    UNSHARPEN = 'UNSHARPEN',
    UPLOAD_PHOTO = 'UPLOAD_PHOTO',
    VIEW = 'VIEW',
    ZOOM_IN = 'ZOOM_IN',
    ZOOM_OUT = 'ZOOM_OUT',
}
