import { css } from '@emotion/css';

import type { BackgroundCssProps } from './types';

export const shadowStyle = (top?: boolean, bottom?: boolean) => css`
    position: relative;
    display: flex;
    flex-direction: ${top === true || bottom === true ? 'column' : 'row'};
    width: 100%;
    .dex-shadow__hide {
        display: none;
    }
`;
export const backgroundCss = ({ height = '30px', type, width = '100%' }: BackgroundCssProps) => css`
    height: ${height};
    width: ${width};
    position: absolute;
    ${type}: 0;
    z-index: 1;
    background: -moz-linear-gradient(${type}, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 255, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        ${type} left,
        ${type} right,
        color-stop(0%, rgba(255, 0, 0, 0.1)),
        color-stop(100%, rgba(0, 0, 255, 1))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        ${type},
        rgba(255, 0, 0, 0) 0%,
        rgba(0, 0, 255, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(${type}, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 255, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(${type}, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 255, 1) 100%); /* IE10+ */
    background: linear-gradient(to ${type}, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
    pointer-events: none;
`;
