import type { MouseEvent } from 'react';
import React, { useId, useState } from 'react';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Button } from '@dexter/dex-component-library';
import { Icon } from '@dexter/dex-icon-library';

import { EnsemblesThumbnail } from './EnsemblesThumbnail';
import { buttonStyle, galleryActionStyle, thumbnailOverlayStyle } from './GalleryCss';
import { TIME_LIMIT } from '../constants';
import { getTemplateInformation } from '../helpers';
import type { ImageThumbnailProps, ThumbnailButtonConfig } from '../types';

export const ImageThumbnail = ({
    icons,
    id,
    pageNumber = 1,
    info,
    onAdd,
    setClickedAssetId,
    clickedAssetId,
    isMultiPageAsset,
    setClickedPageNumber,
    clickedPageNumber,
    proportion = 1,
    ensembles,
    onEnsembleClick,
    clickedTemplateId,
    setClickedTemplateId,
    setShowLoader,
    isLoaded,
    title,
    subTitle,
    isOverlayNeeded = true,
}: ImageThumbnailProps) => {
    const [lastClickedTimestamp, setLastClickedTimestamp] = useState(0);
    const idElementInfo = useId();
    const idIcon = useId();
    const { isSmallDevice } = useBreakpointInfo();

    const elementInfo = info?.map((item) => {
        const key = `${item}_${idElementInfo}`;
        return (
            item && (
                <div className="dex-gallery-info" title={item} key={key}>
                    {item}
                </div>
            )
        );
    });

    const handleThumbnailClick = (event: MouseEvent<HTMLButtonElement>, icon: ThumbnailButtonConfig) => {
        event.stopPropagation();
        icon.action(id, pageNumber);
    };

    const iconContent = icons.map((icon: ThumbnailButtonConfig) => {
        const iconPosition = icon.position.toLocaleLowerCase().split('_').join(' dex-');
        const key = `${icon.icon.name}_${idIcon}`;

        return (
            <div
                className={cx(
                    galleryActionStyle,
                    `dex-gallery-action dex-${iconPosition} dex-${icon.icon.name.toLowerCase()}`,
                )}
                key={key}
            >
                <Button
                    className={cx(
                        `dex-${icon.icon.name.toLowerCase()}`,
                        buttonStyle(
                            true,
                            icon.customCss?.background,
                            icon.customCss?.color,
                            icon.customCss?.hoverBackground,
                            icon.customCss?.hoverColor,
                        ),
                    )}
                    onClick={(event) => handleThumbnailClick(event, icon)}
                >
                    <Icon content={icon.icon.icon} size="extra-small" />
                </Button>
            </div>
        );
    });

    if (ensembles) {
        /**
         * As per the PR https://github.com/pixartprinting/dexter.packages/pull/928, now we are getting more information as mentioned below instead of just ensembleId.
         * So, we have fetched the ensembleId id from getTemplateInformation function as per the new information.
         *  JSON.stringify({
                ensembleId: selectedEnsemble.ensembleId,
                ensembleLineId: '',
                colorDominance: selectedEnsemble.colorDominance,
                scenePreviewDefaultUrl: selectedEnsemble.scenePreviewDefaultUrl,
                defaultTemplateToken: selectedEnsemble.defaultTemplateToken,
            });
         *  */
        const { ensembleId } = getTemplateInformation(id);

        return (
            <EnsemblesThumbnail
                iconContent={iconContent}
                ensembles={ensembles}
                proportion={proportion}
                onEnsembleClick={onEnsembleClick}
                selectedEnsembleId={ensembleId}
                clickedTemplateId={clickedTemplateId}
                setClickedTemplateId={setClickedTemplateId}
                onAdd={onAdd}
                setShowLoader={setShowLoader}
                isLoaded={isLoaded}
                title={title}
                subTitle={subTitle}
            />
        );
    }

    // TODO: Improvement needed
    const handleClick = ({ timeStamp }: MouseEvent) => {
        if (isMultiPageAsset) {
            if (!isSmallDevice) {
                onAdd?.(id, pageNumber);
            } else if (lastClickedTimestamp && clickedAssetId === id) {
                onAdd?.(id, pageNumber);
                setLastClickedTimestamp(0);
            } else {
                setLastClickedTimestamp(timeStamp);
                setClickedAssetId?.(id);
            }
        } else if (lastClickedTimestamp && timeStamp - lastClickedTimestamp <= TIME_LIMIT) {
            onAdd?.(id, pageNumber);
        } else {
            setLastClickedTimestamp(timeStamp);

            if (!isOverlayNeeded) {
                setClickedAssetId?.(id);
                onAdd?.(id, pageNumber);
            }

            if (isSmallDevice) {
                setClickedAssetId?.(id);
                setClickedPageNumber?.(pageNumber);
            }
        }
    };

    return (
        <div
            id={`${pageNumber}`}
            className={cx(
                'dex-gallery-image-thumbnail',
                thumbnailOverlayStyle(
                    clickedPageNumber ? pageNumber === clickedPageNumber : clickedAssetId === id,
                    isSmallDevice,
                    isOverlayNeeded,
                ),
            )}
            onClick={(event) => {
                handleClick(event);
            }}
        >
            <div className="dex-gallery-infoContainer">
                {info && elementInfo && <div className="dex-gallery-info-box">{elementInfo}</div>}
            </div>
            {iconContent}
        </div>
    );
};
