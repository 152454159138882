import React from 'react';

import { cx } from '@emotion/css';

import { Icon } from '@dexter/dex-icon-library';

import { ICON_GALLERY_DEFAULT_ICON_SIZE, PREFIX_ICON_GALLERY } from './constants';
import { iconBoxStyle } from './IconGalleryCss';
import type { IconBoxProps } from './types';

export const IconBox = ({
    id,
    icon,
    iconSize = ICON_GALLERY_DEFAULT_ICON_SIZE,
    label,
    onClick,
    className,
    width = 100,
    height = 100,
    isActive = false,
}: IconBoxProps) => (
    <div className={cx(`${PREFIX_ICON_GALLERY}-box`, className, iconBoxStyle(width, height))}>
        <div className={cx([`${PREFIX_ICON_GALLERY}-box__icon`, isActive && 'active'])} onClick={() => onClick?.(id)}>
            <Icon size={iconSize} content={icon.icon} />
        </div>
        {label && <span className={`${PREFIX_ICON_GALLERY}-box__label`}>{label}</span>}
    </div>
);
