import type { DetailedHTMLProps, ReactNode, ReactSVGElement, SVGAttributes } from 'react';
import React, { createElement } from 'react';

export type IconSizeNames = 'extra-small' | 'small' | 'medium' | 'default' | 'large' | 'extra-large' | 'ultra-large';

interface BaseIconProps extends DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement> {
    size?: IconSizeNames;
}

export type IconContent = string | ReactSVGElement;

export interface IconProps extends BaseIconProps {
    content: IconContent;
}

export enum IconSizes {
    ExtraSmall = 12,
    Small = 16,
    Medium = 20,
    Default = 24,
    Large = 36,
    ExtraLarge = 48,
    UltraLarge = 60,
}

export function getIconSizeFromString(size: IconSizeNames) {
    switch (size) {
        case 'extra-small':
            return IconSizes.ExtraSmall;
        case 'small':
            return IconSizes.Small;
        case 'medium':
            return IconSizes.Medium;
        case 'large':
            return IconSizes.Large;
        case 'extra-large':
            return IconSizes.ExtraLarge;
        case 'ultra-large':
            return IconSizes.UltraLarge;
        case 'default':
        default:
            return IconSizes.Default;
    }
}

function hasContent(props: IconProps): props is IconProps {
    return props.content !== undefined;
}
interface IconErrorBoundaryProps {
    children: ReactNode;
}

// Needs Test Cases to ensure it works correctly when an invalid icon string is provided.
class IconErrorBoundary extends React.Component<IconErrorBoundaryProps> {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    // eslint-disable-next-line class-methods-use-this
    componentDidCatch() {
        // eslint-disable-next-line no-console
        console.error(`IconString provided was invalid.`);
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}

export function Icon(props: Readonly<IconProps>) {
    const { size, viewBox = '0 0 24 24', ...restOfProps } = props;
    let content: IconContent;
    let height: number | undefined;
    let width: number | undefined;

    if (size) {
        const sizePx = getIconSizeFromString(size);
        height = sizePx;
        width = sizePx;
    }

    if (hasContent(props)) {
        content = props.content;
        // Prevent `content` from being added to the underlying SVG element
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (restOfProps as any).content;
        if (typeof content === 'function') {
            return createElement(content, {
                viewBox,
                width: width || IconSizes.Default,
                height: height || IconSizes.Default,
                ...restOfProps,
            });
            // eslint-disable-next-line no-else-return
        } else if (typeof content === 'string' && content.startsWith('<svg')) {
            return (
                <IconErrorBoundary>
                    <svg
                        viewBox={viewBox}
                        width={width || IconSizes.Default}
                        height={height || IconSizes.Default}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                        {...restOfProps}
                    />
                </IconErrorBoundary>
            );
        }
    }

    return null;
}
