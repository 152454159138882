import React, { useState } from 'react';

import { css, cx } from '@emotion/css';

import { Icon } from '@dexter/dex-icon-library';

import { useCollapsibleContext } from './CollapsibleContext';
import { useCollapsibleItemContext } from './CollapsibleItemContext';
import type { CollapsibleHeaderProps } from './types';
import { CollapsibleIconAlignment } from './types';
import { mediaQueries } from '../theme';

const collapsibleHeader = css`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 16px;

    ${mediaQueries.small} {
        padding: 10px 8px;
    }
`;

const collapsibleHeaderRight = css`
    justify-content: space-between;

    .dsc-collapsible-header__content {
        margin-right: 6px;
    }
`;

const collapsibleHeaderLeft = css`
    flex-direction: row-reverse;
    justify-content: start;

    .dsc-collapsible-header__content {
        margin-left: 12px;
    }
`;

export const collapsibleHeaderAlignmentStyles = {
    [CollapsibleIconAlignment.Left]: collapsibleHeaderLeft,
    [CollapsibleIconAlignment.Right]: collapsibleHeaderRight,
};

const collapsibleHeaderIcon = css`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.25s ease-in-out;
`;

export function CollapsibleHeader({ className, children }: CollapsibleHeaderProps) {
    const [isFocused, setIsFocussed] = useState(false);
    const { iconAlignment, icon } = useCollapsibleContext();
    const { isOpen, toggleIsOpen, id } = useCollapsibleItemContext();
    const alignmentStyle = collapsibleHeaderAlignmentStyles[iconAlignment];

    const onKeyDown = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isFocused) return;

        if (key === 'Enter') toggleIsOpen();
    };

    return (
        <div
            role="button"
            className={cx('dsc-collapsible-header', collapsibleHeader, alignmentStyle, className)}
            data-testid={`dsc-collapsible-header--${id}`}
            onClick={toggleIsOpen}
            onFocus={() => setIsFocussed(true)}
            onBlur={() => setIsFocussed(false)}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            <div className="dsc-collapsible-header__content">{children}</div>
            <div
                className={cx('dsc-collapsible-header__icon', collapsibleHeaderIcon)}
                data-testid="dsc-collapsible-header-icon"
            >
                <Icon content={isOpen ? icon.open : icon.close} size="small" />
            </div>
        </div>
    );
}
