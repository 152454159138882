import React from 'react';

import { css, cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { useIcons } from '@dexter/dex-icon-library';

import { Banner } from './Banner';
import type { Variant } from './types';
import { BannerType } from './types';

export const bannerStyle = (isSmallDevice: boolean) => css`
    ${!isSmallDevice && `margin-top: 16px;`}
    margin-bottom: 32px;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    text-align: left;
    svg {
        height: 16px;
        width: 16px;
    }
`;

export interface BannerMessageProps {
    message: string;
    variant: Variant;
}

// TODO: Use this component for validations as well
export const BannerMessage = ({ message, variant }: BannerMessageProps) => {
    const {
        PIXART_NOTIFY_WARNING: error,
        PIXART_NOTIFY_WARNING: info,
        PIXART_NOTIFY_WARNING: warning,
        PIXART_NOTIFY_SUCCESS: success,
    } = useIcons();
    const { isSmallDevice } = useBreakpointInfo();

    const iconsT = {
        error,
        warning,
        success,
        info,
    };

    const icon = { variant: { icon: iconsT[variant].icon } };

    return (
        <Banner
            className={cx(bannerStyle(isSmallDevice))}
            variant={variant}
            icons={icon}
            bannerType={BannerType.message}
        >
            {message}
        </Banner>
    );
};
