import { css } from '@emotion/css';

import { PREFIX_ICON_GALLERY } from './constants';
import { cvar, mediaQueries } from '../theme';

export const iconBoxStyle = (width = 100, height = 100) => css`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    ${mediaQueries.small} {
        margin-right: 7px;
    }
    .${PREFIX_ICON_GALLERY}-box__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${cvar('primaryBorderColor')};
        width: ${width}px;
        height: ${height}px;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        &.active {
            border: 1px solid ${cvar('primaryColor')};
        }
        > svg {
            color: ${cvar('primaryColor')};
        }
        :hover {
            border: 1px solid ${cvar('primaryColor')};
        }
    }
    .${PREFIX_ICON_GALLERY}-box__label {
        width: ${width}px;
        font-size: 12px;
        margin-top: 8px;
    }
`;

export const iconGalleryWrapperStyle = css`
    display: flex;
    flex-direction: column;
    color: ${cvar('primaryTextColor')};
    font-family: ${cvar('customFontFamily')};
    font-size: 14px;
    .${PREFIX_ICON_GALLERY}-wrapper__label {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        > span:first-child {
            font-weight: 700;
        }
        .dsc-tooltip__trigger {
            > span:first-child {
                display: flex;
                color: ${cvar('primaryColor')};
                cursor: pointer;
            }
        }
    }
    .${PREFIX_ICON_GALLERY}-wrapper__children {
        display: flex;
        overflow: scroll;
        margin-bottom: 33px;
        ${mediaQueries.small} {
            margin-bottom: 25px;
        }
        /* Hiding the scroll bars */
        /* Webkit */
        ::-webkit-scrollbar {
            display: none;
        }
        /* IE and Edge */
        -ms-overflow-style: none;
        /* Firefox */
        scrollbar-width: none;
        .${PREFIX_ICON_GALLERY}-box:last-child {
            margin-right: 0;
        }
    }
`;
