import React from 'react';

import { css, cx } from '@emotion/css';

import { useIcons } from '@dexter/dex-icon-library';

import { CollapsibleProvider } from './CollapsibleContext';
import { CollapsibleItem } from './CollapsibleItem';
import type { CollapsibleOptions, CollapsibleProps } from './types';
import { CollapsibleIconAlignment } from './types';

const collapsibleStyle = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const defaultCollapsibleOptions: CollapsibleOptions = {
    isAccordionMode: true,
    iconAlignment: CollapsibleIconAlignment.Right,
};
function Collapsible({ children, className, options = defaultCollapsibleOptions }: CollapsibleProps) {
    const { ARROW_UP, ARROW_DOWN } = useIcons();
    const {
        isAccordionMode = true,
        iconAlignment = CollapsibleIconAlignment.Right,
        defaultActiveIds = [],
        icon = { close: ARROW_DOWN.icon, open: ARROW_UP.icon },
    } = options;

    return (
        <CollapsibleProvider
            defaultActiveIds={defaultActiveIds}
            isAccordionMode={isAccordionMode}
            iconAlignment={iconAlignment}
            icon={icon}
        >
            <ul className={cx('dsc-collapsible', className, collapsibleStyle)}>{children}</ul>
        </CollapsibleProvider>
    );
}

Collapsible.Item = CollapsibleItem;

export { Collapsible };
