import React, { useEffect } from 'react';

import { cx } from '@emotion/css';

import { backgroundCss, shadowStyle } from './ShadowCss';
import type { ShadowProps } from './types';

export const Shadow: React.FC<ShadowProps> = ({
    scrollRef,
    children,
    className,
    top,
    left,
    right,
    bottom,
    scrollTarget,
}) => {
    const shadowLeftRef = React.useRef<HTMLDivElement>(null);
    const shadowRightRef = React.useRef<HTMLDivElement>(null);
    const shadowTopRef = React.useRef<HTMLDivElement>(null);
    const shadowBottomRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollRefElement = scrollRef?.current || document.querySelector(`.dex-shadow ${scrollTarget}`);
        const handleScroll = () => {
            const newScrollPosition =
                top || bottom ? scrollRefElement?.scrollTop || 0 : scrollRefElement?.scrollLeft || 0;
            const start = newScrollPosition === 0;
            const center = newScrollPosition >= 1;
            const end =
                top || bottom
                    ? Math.round(newScrollPosition) + Math.round(Number(scrollRefElement?.clientHeight)) + 5 >=
                      (scrollRefElement?.scrollHeight || 0)
                    : Math.round(newScrollPosition) + Math.round(Number(scrollRefElement?.clientWidth)) >=
                      (scrollRefElement?.scrollWidth || 0);
            if (start) {
                if (top) {
                    shadowTopRef.current?.classList.add('dex-shadow__hide');
                } else {
                    shadowLeftRef.current?.classList.add('dex-shadow__hide');
                }
            } else if (end) {
                if (bottom) {
                    shadowBottomRef.current?.classList.add('dex-shadow__hide');
                } else {
                    shadowRightRef.current?.classList.add('dex-shadow__hide');
                }
            } else if (center) {
                if (left) {
                    shadowLeftRef.current?.classList.remove('dex-shadow__hide');
                }
                if (right) {
                    shadowRightRef.current?.classList.remove('dex-shadow__hide');
                }
                if (top) {
                    shadowTopRef.current?.classList.remove('dex-shadow__hide');
                }
                if (bottom) {
                    shadowBottomRef.current?.classList.remove('dex-shadow__hide');
                }
            }
        };

        scrollRefElement?.addEventListener('scroll', handleScroll);
        return () => {
            scrollRefElement?.removeEventListener('scroll', handleScroll);
        };
    }, [scrollRef, scrollTarget, top, bottom, left, right]);

    useEffect(() => {
        function checkScrolling() {
            const scrollRefElement = scrollRef?.current || document.querySelector(`.dex-shadow ${scrollTarget}`);
            if (scrollRefElement) {
                const isScrolling = scrollRefElement.scrollHeight > scrollRefElement.clientHeight;
                if (!isScrolling) {
                    if (bottom) {
                        shadowBottomRef.current?.classList.add('dex-shadow__hide');
                    }
                    if (right) {
                        shadowRightRef.current?.classList.add('dex-shadow__hide');
                    }
                }
            }
        }
        checkScrolling();
    }, [bottom, scrollRef, scrollTarget, right]);

    return (
        <div className={cx('dex-shadow', shadowStyle(top, bottom), className)}>
            {left && (
                <div
                    ref={shadowLeftRef}
                    className={cx(
                        'dex-shadow__left dex-shadow__hide',
                        backgroundCss({ height: '100%', type: 'left', width: '30px' }),
                    )}
                ></div>
            )}
            {top && (
                <div
                    ref={shadowTopRef}
                    className={cx('dex-shadow__top dex-shadow__hide', backgroundCss({ type: 'top' }))}
                ></div>
            )}

            {children}
            {bottom && (
                <div
                    ref={shadowBottomRef}
                    className={cx('dex-shadow__bottom', backgroundCss({ type: 'bottom' }))}
                ></div>
            )}
            {right && (
                <div
                    ref={shadowRightRef}
                    className={cx('dex-shadow__right', backgroundCss({ height: '100%', type: 'right', width: '30px' }))}
                ></div>
            )}
        </div>
    );
};
