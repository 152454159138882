import type { ReactNode } from 'react';

import type { IconsProp } from '../types';

export type BannerVariant = 'success' | 'info' | 'warning' | 'error';

export interface BannerContent {
    variant: BannerVariant;
    message: string;
}

export enum BannerType {
    notification = 'notification',
    message = 'message',
}

interface BannerIcons extends IconsProp {
    variant: {
        icon: string;
    };
}

export interface BannerProps {
    variant?: BannerVariant;
    icons?: BannerIcons;
    children: ReactNode | ReactNode[];
    onDismiss?: () => void;
    className?: string;
    bannerType?: BannerType;
}

// TODO: Use the enum as type and replace BannerVariant with this, modify at all places where hardcoded values are used
export const enum Variant {
    error = 'error',
    warning = 'warning',
    success = 'success',
    info = 'info',
}
