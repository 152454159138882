import React from 'react';

import { cx } from '@emotion/css';

import { Icon, useIcons } from '@dexter/dex-icon-library';

import { PREFIX_ICON_GALLERY } from './constants';
import { iconGalleryWrapperStyle } from './IconGalleryCss';
import type { IconGalleryWrapperProps } from './types';
import { Shadow } from '../shadow';
import { Tooltip } from '../tooltip';

export const IconGalleryWrapper = ({ children, label, infoIconTooltip, className }: IconGalleryWrapperProps) => {
    const { INFO } = useIcons();

    return (
        <div className={cx(iconGalleryWrapperStyle, `${PREFIX_ICON_GALLERY}-wrapper`, className)}>
            <div className={`${PREFIX_ICON_GALLERY}-wrapper__label`}>
                <span>{label}</span>
                {infoIconTooltip && (
                    <Tooltip content={infoIconTooltip} variant="optionInfo" placement="bottom">
                        <Icon content={INFO.icon} />
                    </Tooltip>
                )}
            </div>
            <Shadow left={true} right={true} scrollTarget={`.${className}`}>
                <div className={`${PREFIX_ICON_GALLERY}-wrapper__children ${className}`}>{children}</div>
            </Shadow>
        </div>
    );
};
