/* 🤖 this file was generated by svg-to-ts */
import { dexAdd1F } from './dex-add1f.icon';
import { dexAddBold } from './dex-addBold.icon';
import { dexAlertCircle } from './dex-alertCircle.icon';
import { dexAlertTriangle } from './dex-alertTriangle.icon';
import { dexAlignBottom } from './dex-alignBottom.icon';
import { dexAlignCenter } from './dex-alignCenter.icon';
import { dexAlignLeft } from './dex-alignLeft.icon';
import { dexAlignMiddle } from './dex-alignMiddle.icon';
import { dexAlignRight } from './dex-alignRight.icon';
import { dexAlignTop } from './dex-alignTop.icon';
import { dexArrowBack } from './dex-arrowBack.icon';
import { dexArrowDown } from './dex-arrowDown.icon';
import { dexArrowLeft } from './dex-arrowLeft.icon';
import { dexArrowRight } from './dex-arrowRight.icon';
import { dexArrowUp } from './dex-arrowUp.icon';
import { dexBin } from './dex-bin.icon';
import { dexBinFilled } from './dex-binFilled.icon';
import { dexBringForward } from './dex-bringForward.icon';
import { dexBringToTheFront } from './dex-bringToTheFront.icon';
import { dexCheck } from './dex-check.icon';
import { dexCheckCircle } from './dex-checkCircle.icon';
import { dexClipArt } from './dex-clipArt.icon';
import { dexClose } from './dex-close.icon';
import { dexCloseCircle } from './dex-closeCircle.icon';
import { dexCloseDex } from './dex-closeDex.icon';
import { dexColorBrushPaint } from './dex-colorBrushPaint.icon';
import { dexColorDropPick } from './dex-colorDropPick.icon';
import { dexColorFill } from './dex-colorFill.icon';
import { dexColorPicker } from './dex-colorPicker.icon';
import { dexColorPickerCt } from './dex-colorPickerCT.icon';
import { dexColorStroke } from './dex-colorStroke.icon';
import { dexCrop } from './dex-crop.icon';
import { dexCropCt } from './dex-cropCT.icon';
import { dexCutline } from './dex-cutline.icon';
import { dexDelete } from './dex-delete.icon';
import { dexDuplicate } from './dex-duplicate.icon';
import { dexDuplicateItem } from './dex-duplicateItem.icon';
import { dexEditPattern } from './dex-editPattern.icon';
import { dexEditTextArea } from './dex-editTextArea.icon';
import { dexEvents } from './dex-events.icon';
import { dexFileUpload } from './dex-fileUpload.icon';
import { dexFilter } from './dex-filter.icon';
import { dexFolder } from './dex-folder.icon';
import { dexFolderCt } from './dex-folderCT.icon';
import { dexFontSize } from './dex-fontSize.icon';
import { dexGraphic } from './dex-graphic.icon';
import { dexHolidaysPanel } from './dex-holidaysPanel.icon';
import { dexImage } from './dex-image.icon';
import { dexImagesPanel } from './dex-imagesPanel.icon';
import { dexInfo } from './dex-info.icon';
import { dexInfoCircle } from './dex-infoCircle.icon';
import { dexItemAlignmentBottomIcon } from './dex-itemAlignmentBottomIcon.icon';
import { dexItemAlignmentCenterHorizontallyIcon } from './dex-itemAlignmentCenterHorizontallyIcon.icon';
import { dexItemAlignmentCenterVerticallyIcon } from './dex-itemAlignmentCenterVerticallyIcon.icon';
import { dexItemAlignmentLeftIcon } from './dex-itemAlignmentLeftIcon.icon';
import { dexItemAlignmentRightIcon } from './dex-itemAlignmentRightIcon.icon';
import { dexItemAlignmentTopIcon } from './dex-itemAlignmentTopIcon.icon';
import { dexLayoutsPanel } from './dex-layoutsPanel.icon';
import { dexLineWeight } from './dex-lineWeight.icon';
import { dexListBullets } from './dex-listBullets.icon';
import { dexListNumbers } from './dex-listNumbers.icon';
import { dexLock } from './dex-lock.icon';
import { dexLockCt } from './dex-lockCT.icon';
import { dexLockFilled } from './dex-lockFilled.icon';
import { dexLockUnlock } from './dex-lockUnlock.icon';
import { dexLockUnlockFilled } from './dex-lockUnlockFilled.icon';
import { dexMoveBackward } from './dex-moveBackward.icon';
import { dexMoveForward } from './dex-moveForward.icon';
import { dexMoveToBottom } from './dex-moveToBottom.icon';
import { dexMoveToTop } from './dex-moveToTop.icon';
import { dexMultiSelect } from './dex-multiSelect.icon';
import { dexOpacity } from './dex-opacity.icon';
import { dexOrientationL } from './dex-orientationL.icon';
import { dexOrientationP } from './dex-orientationP.icon';
import { dexOrientationPa } from './dex-orientationPA.icon';
import { dexOrientationS } from './dex-orientationS.icon';
import { dexPageTooltip } from './dex-pageTooltip.icon';
import { dexPattern } from './dex-pattern.icon';
import { dexPatternTheme } from './dex-patternTheme.icon';
import { dexPencil } from './dex-pencil.icon';
import { dexPhotoEditor } from './dex-photoEditor.icon';
import { dexPixart360 } from './dex-pixart360.icon';
import { dexPixartAdd } from './dex-pixartAdd.icon';
import { dexPixartAiStudioTab } from './dex-pixartAiStudioTab.icon';
import { dexPixartArrowDown } from './dex-pixartArrowDown.icon';
import { dexPixartArrowMaximize } from './dex-pixartArrowMaximize.icon';
import { dexPixartArrowMinimize } from './dex-pixartArrowMinimize.icon';
import { dexPixartArrowNext } from './dex-pixartArrowNext.icon';
import { dexPixartArrowPrev } from './dex-pixartArrowPrev.icon';
import { dexPixartArrowUp } from './dex-pixartArrowUp.icon';
import { dexPixartBackgroundTab } from './dex-pixartBackgroundTab.icon';
import { dexPixartBulb } from './dex-pixartBulb.icon';
import { dexPixartCancelIcon } from './dex-pixartCancelIcon.icon';
import { dexPixartColorTool } from './dex-pixartColorTool.icon';
import { dexPixartCropCancel } from './dex-pixartCropCancel.icon';
import { dexPixartCropCheck } from './dex-pixartCropCheck.icon';
import { dexPixartCrossIcon } from './dex-pixartCrossIcon.icon';
import { dexPixartDeleteEmpty } from './dex-pixartDeleteEmpty.icon';
import { dexPixartEditButton } from './dex-pixartEditButton.icon';
import { dexPixartElementTab } from './dex-pixartElementTab.icon';
import { dexPixartEmptyColorBig } from './dex-pixartEmptyColorBig.icon';
import { dexPixartEmptyColorSmall } from './dex-pixartEmptyColorSmall.icon';
import { dexPixartFlipHorizontal } from './dex-pixartFlipHorizontal.icon';
import { dexPixartFlipVertical } from './dex-pixartFlipVertical.icon';
import { dexPixartFontFamilyIcon } from './dex-PixartFontFamilyIcon.icon';
import { dexPixartFontSizeIcon } from './dex-pixartFontSizeIcon.icon';
import { dexPixartGear } from './dex-pixartGear.icon';
import { dexPixartImageTab } from './dex-pixartImageTab.icon';
import { dexPixartInvertColor } from './dex-pixartInvertColor.icon';
import { dexPixartInvertColorReverse } from './dex-pixartInvertColorReverse.icon';
import { dexPixartMagicWand } from './dex-pixartMagicWand.icon';
import { dexPixartMagicWandTransparent } from './dex-pixartMagicWandTransparent.icon';
import { dexPixartMinusIcon } from './dex-pixartMinusIcon.icon';
import { dexPixartMove } from './dex-pixartMove.icon';
import { dexPixartNotifySuccess } from './dex-pixartNotifySuccess.icon';
import { dexPixartNotifyWarning } from './dex-pixartNotifyWarning.icon';
import { dexPixartNotifyWarningOutline } from './dex-pixartNotifyWarningOutline.icon';
import { dexPixartPlusIcon } from './dex-pixartPlusIcon.icon';
import { dexPixartRainbowCancelLarge } from './dex-pixartRainbowCancelLarge.icon';
import { dexPixartRainbowCancelSmall } from './dex-pixartRainbowCancelSmall.icon';
import { dexPixartRainbowPlusLarge } from './dex-pixartRainbowPlusLarge.icon';
import { dexPixartRainbowPlusSmall } from './dex-pixartRainbowPlusSmall.icon';
import { dexPixartRefreshButton } from './dex-pixartRefreshButton.icon';
import { dexPixartSpacingIcon } from './dex-pixartSpacingIcon.icon';
import { dexPixartTemplateTab } from './dex-pixartTemplateTab.icon';
import { dexPixartTextcolor } from './dex-pixartTextcolor.icon';
import { dexPixartTextTab } from './dex-pixartTextTab.icon';
import { dexPixartZoomInIcon } from './dex-pixartZoomInIcon.icon';
import { dexPosition } from './dex-position.icon';
import { dexPreview } from './dex-preview.icon';
import { dexPreviewHide } from './dex-previewHide.icon';
import { dexQrCode } from './dex-qrCode.icon';
import { dexRedo } from './dex-redo.icon';
import { dexRedoCt } from './dex-redoCT.icon';
import { dexRegulate } from './dex-regulate.icon';
import { dexRemoveBackground } from './dex-removeBackground.icon';
import { dexReplace } from './dex-replace.icon';
import { dexReplaceImage } from './dex-replaceImage.icon';
import { dexResizeBox } from './dex-resizeBox.icon';
import { dexResizeExpandSides } from './dex-resizeExpandSides.icon';
import { dexRotateBack } from './dex-rotateBack.icon';
import { dexRotateBackFilled } from './dex-rotateBackFilled.icon';
import { dexRotateForward } from './dex-rotateForward.icon';
import { dexRotateForwardCt } from './dex-rotateForwardCT.icon';
import { dexRotateForwardFilled } from './dex-rotateForwardFilled.icon';
import { dexScaleHeight } from './dex-scaleHeight.icon';
import { dexSearch } from './dex-search.icon';
import { dexSearchDex } from './dex-searchDex.icon';
import { dexSendBackward } from './dex-sendBackward.icon';
import { dexSendToTheBack } from './dex-sendToTheBack.icon';
import { dexSettings } from './dex-settings.icon';
import { dexShape } from './dex-shape.icon';
import { dexShapeCircle } from './dex-shapeCircle.icon';
import { dexShapeCustom } from './dex-shapeCustom.icon';
import { dexShapeLoose } from './dex-shapeLoose.icon';
import { dexShapeMedium } from './dex-shapeMedium.icon';
import { dexShapeRounded } from './dex-shapeRounded.icon';
import { dexShapeSquare } from './dex-shapeSquare.icon';
import { dexShapeTight } from './dex-shapeTight.icon';
import { dexSharpen } from './dex-sharpen.icon';
import { dexShowMore } from './dex-showMore.icon';
import { dexShuffle } from './dex-shuffle.icon';
import { dexText } from './dex-text.icon';
import { dexTextAlignmentCenter } from './dex-textAlignmentCenter.icon';
import { dexTextAlignmentCenterCt } from './dex-textAlignmentCenterCT.icon';
import { dexTextAlignmentLeft } from './dex-textAlignmentLeft.icon';
import { dexTextAlignmentLeftCt } from './dex-textAlignmentLeftCT.icon';
import { dexTextAlignmentRight } from './dex-textAlignmentRight.icon';
import { dexTextAlignmentRightCt } from './dex-textAlignmentRightCT.icon';
import { dexTextBold } from './dex-textBold.icon';
import { dexTextBoldCt } from './dex-textBoldCT.icon';
import { dexTextCasingDefault } from './dex-textCasingDefault.icon';
import { dexTextCasingLower } from './dex-textCasingLower.icon';
import { dexTextCasingUpper } from './dex-textCasingUpper.icon';
import { dexTextItalic } from './dex-textItalic.icon';
import { dexTextItalicCt } from './dex-textItalicCT.icon';
import { dexTextListBullets } from './dex-textListBullets.icon';
import { dexTextListNumbers } from './dex-textListNumbers.icon';
import { dexTextLowercase } from './dex-textLowercase.icon';
import { dexTextMixedCase } from './dex-textMixedCase.icon';
import { dexTextStrikeout } from './dex-textStrikeout.icon';
import { dexTextStrikeoutCt } from './dex-textStrikeoutCT.icon';
import { dexTextUnderline } from './dex-textUnderline.icon';
import { dexTextUnderlineCt } from './dex-textUnderlineCT.icon';
import { dexTextUppercase } from './dex-textUppercase.icon';
import { dexUndo } from './dex-undo.icon';
import { dexUndoCt } from './dex-undoCT.icon';
import { dexUnlock } from './dex-unlock.icon';
import { dexUnsharpen } from './dex-unsharpen.icon';
import { dexUploadPhoto } from './dex-uploadPhoto.icon';
import { dexView } from './dex-view.icon';
import { dexZoomIn } from './dex-zoomIn.icon';
import { dexZoomOut } from './dex-zoomOut.icon';
import type { DexIcon } from './iconManifest';

export const completeIconSet: DexIcon[] = [
    dexAdd1F as DexIcon,
    dexAddBold as DexIcon,
    dexAlertCircle as DexIcon,
    dexAlertTriangle as DexIcon,
    dexAlignBottom as DexIcon,
    dexAlignCenter as DexIcon,
    dexAlignLeft as DexIcon,
    dexAlignMiddle as DexIcon,
    dexAlignRight as DexIcon,
    dexAlignTop as DexIcon,
    dexArrowBack as DexIcon,
    dexArrowDown as DexIcon,
    dexArrowLeft as DexIcon,
    dexArrowRight as DexIcon,
    dexArrowUp as DexIcon,
    dexBin as DexIcon,
    dexBinFilled as DexIcon,
    dexBringForward as DexIcon,
    dexBringToTheFront as DexIcon,
    dexCheck as DexIcon,
    dexCheckCircle as DexIcon,
    dexClipArt as DexIcon,
    dexClose as DexIcon,
    dexCloseCircle as DexIcon,
    dexCloseDex as DexIcon,
    dexColorBrushPaint as DexIcon,
    dexColorDropPick as DexIcon,
    dexColorFill as DexIcon,
    dexColorPicker as DexIcon,
    dexColorPickerCt as DexIcon,
    dexColorStroke as DexIcon,
    dexCrop as DexIcon,
    dexCropCt as DexIcon,
    dexCutline as DexIcon,
    dexDelete as DexIcon,
    dexDuplicate as DexIcon,
    dexDuplicateItem as DexIcon,
    dexEditPattern as DexIcon,
    dexEditTextArea as DexIcon,
    dexEvents as DexIcon,
    dexFileUpload as DexIcon,
    dexFilter as DexIcon,
    dexFolder as DexIcon,
    dexFolderCt as DexIcon,
    dexFontSize as DexIcon,
    dexGraphic as DexIcon,
    dexHolidaysPanel as DexIcon,
    dexImage as DexIcon,
    dexImagesPanel as DexIcon,
    dexInfo as DexIcon,
    dexInfoCircle as DexIcon,
    dexItemAlignmentBottomIcon as DexIcon,
    dexItemAlignmentCenterHorizontallyIcon as DexIcon,
    dexItemAlignmentCenterVerticallyIcon as DexIcon,
    dexItemAlignmentLeftIcon as DexIcon,
    dexItemAlignmentRightIcon as DexIcon,
    dexItemAlignmentTopIcon as DexIcon,
    dexLayoutsPanel as DexIcon,
    dexLineWeight as DexIcon,
    dexListBullets as DexIcon,
    dexListNumbers as DexIcon,
    dexLock as DexIcon,
    dexLockCt as DexIcon,
    dexLockFilled as DexIcon,
    dexLockUnlock as DexIcon,
    dexLockUnlockFilled as DexIcon,
    dexMoveBackward as DexIcon,
    dexMoveForward as DexIcon,
    dexMoveToBottom as DexIcon,
    dexMoveToTop as DexIcon,
    dexMultiSelect as DexIcon,
    dexOpacity as DexIcon,
    dexOrientationL as DexIcon,
    dexOrientationP as DexIcon,
    dexOrientationPa as DexIcon,
    dexOrientationS as DexIcon,
    dexPageTooltip as DexIcon,
    dexPattern as DexIcon,
    dexPatternTheme as DexIcon,
    dexPencil as DexIcon,
    dexPhotoEditor as DexIcon,
    dexPixart360 as DexIcon,
    dexPixartAdd as DexIcon,
    dexPixartAiStudioTab as DexIcon,
    dexPixartArrowDown as DexIcon,
    dexPixartArrowMaximize as DexIcon,
    dexPixartArrowMinimize as DexIcon,
    dexPixartArrowNext as DexIcon,
    dexPixartArrowPrev as DexIcon,
    dexPixartArrowUp as DexIcon,
    dexPixartBackgroundTab as DexIcon,
    dexPixartBulb as DexIcon,
    dexPixartCancelIcon as DexIcon,
    dexPixartColorTool as DexIcon,
    dexPixartCropCancel as DexIcon,
    dexPixartCropCheck as DexIcon,
    dexPixartCrossIcon as DexIcon,
    dexPixartDeleteEmpty as DexIcon,
    dexPixartEditButton as DexIcon,
    dexPixartElementTab as DexIcon,
    dexPixartEmptyColorBig as DexIcon,
    dexPixartEmptyColorSmall as DexIcon,
    dexPixartFlipHorizontal as DexIcon,
    dexPixartFlipVertical as DexIcon,
    dexPixartFontFamilyIcon as DexIcon,
    dexPixartFontSizeIcon as DexIcon,
    dexPixartGear as DexIcon,
    dexPixartImageTab as DexIcon,
    dexPixartInvertColor as DexIcon,
    dexPixartInvertColorReverse as DexIcon,
    dexPixartMagicWand as DexIcon,
    dexPixartMagicWandTransparent as DexIcon,
    dexPixartMinusIcon as DexIcon,
    dexPixartMove as DexIcon,
    dexPixartNotifySuccess as DexIcon,
    dexPixartNotifyWarning as DexIcon,
    dexPixartNotifyWarningOutline as DexIcon,
    dexPixartPlusIcon as DexIcon,
    dexPixartRainbowCancelLarge as DexIcon,
    dexPixartRainbowCancelSmall as DexIcon,
    dexPixartRainbowPlusLarge as DexIcon,
    dexPixartRainbowPlusSmall as DexIcon,
    dexPixartRefreshButton as DexIcon,
    dexPixartSpacingIcon as DexIcon,
    dexPixartTemplateTab as DexIcon,
    dexPixartTextcolor as DexIcon,
    dexPixartTextTab as DexIcon,
    dexPixartZoomInIcon as DexIcon,
    dexPosition as DexIcon,
    dexPreview as DexIcon,
    dexPreviewHide as DexIcon,
    dexQrCode as DexIcon,
    dexRedo as DexIcon,
    dexRedoCt as DexIcon,
    dexRegulate as DexIcon,
    dexRemoveBackground as DexIcon,
    dexReplace as DexIcon,
    dexReplaceImage as DexIcon,
    dexResizeBox as DexIcon,
    dexResizeExpandSides as DexIcon,
    dexRotateBack as DexIcon,
    dexRotateBackFilled as DexIcon,
    dexRotateForward as DexIcon,
    dexRotateForwardCt as DexIcon,
    dexRotateForwardFilled as DexIcon,
    dexScaleHeight as DexIcon,
    dexSearch as DexIcon,
    dexSearchDex as DexIcon,
    dexSendBackward as DexIcon,
    dexSendToTheBack as DexIcon,
    dexSettings as DexIcon,
    dexShape as DexIcon,
    dexShapeCircle as DexIcon,
    dexShapeCustom as DexIcon,
    dexShapeLoose as DexIcon,
    dexShapeMedium as DexIcon,
    dexShapeRounded as DexIcon,
    dexShapeSquare as DexIcon,
    dexShapeTight as DexIcon,
    dexSharpen as DexIcon,
    dexShowMore as DexIcon,
    dexShuffle as DexIcon,
    dexText as DexIcon,
    dexTextAlignmentCenter as DexIcon,
    dexTextAlignmentCenterCt as DexIcon,
    dexTextAlignmentLeft as DexIcon,
    dexTextAlignmentLeftCt as DexIcon,
    dexTextAlignmentRight as DexIcon,
    dexTextAlignmentRightCt as DexIcon,
    dexTextBold as DexIcon,
    dexTextBoldCt as DexIcon,
    dexTextCasingDefault as DexIcon,
    dexTextCasingLower as DexIcon,
    dexTextCasingUpper as DexIcon,
    dexTextItalic as DexIcon,
    dexTextItalicCt as DexIcon,
    dexTextListBullets as DexIcon,
    dexTextListNumbers as DexIcon,
    dexTextLowercase as DexIcon,
    dexTextMixedCase as DexIcon,
    dexTextStrikeout as DexIcon,
    dexTextStrikeoutCt as DexIcon,
    dexTextUnderline as DexIcon,
    dexTextUnderlineCt as DexIcon,
    dexTextUppercase as DexIcon,
    dexUndo as DexIcon,
    dexUndoCt as DexIcon,
    dexUnlock as DexIcon,
    dexUnsharpen as DexIcon,
    dexUploadPhoto as DexIcon,
    dexView as DexIcon,
    dexZoomIn as DexIcon,
    dexZoomOut as DexIcon,
];
