import React from 'react';

import { css, cx } from '@emotion/css';

import { Icon, IconSizes, useIcons } from '@dexter/dex-icon-library';

import type { ContentTab } from './types';
import { IconButton } from '../buttons';
import { ButtonToggle, ButtonToggleGroup } from '../buttonToggleGroup';
import { H2 } from '../headings';
import type { SidebarPosition } from '../sidebar';
import { Sidebar } from '../sidebar';
import { cvar } from '../theme';
import type { IconsProp } from '../types';

const getTabBarStyle = (position: SidebarPosition) => css`
    height: 100%;
    width: 72px;
    background: white;
    position: relative;

    .dsc-tab-bar__sidebar,
    .dsc-tab-bar__tab-group {
        position: absolute;
        top: 0;
        ${position === 'left' ? 'left' : 'right'}: 0;
        width: 100%;
        height: 100%;
        pointer-events: auto;
    }

    .dsc-tab-bar__sidebar {
        > .dsc-sidebar {
            ${position === 'left' ? 'left' : 'right'}: 72px;
        }
    }

    .dsc-tab-bar__tab-group {
        background-color: white;
        ${position === 'left'
            ? `border-right: 1px solid ${cvar('inactiveBorderColor')}`
            : `border-left: 1px solid ${cvar('inactiveBorderColor')}`};

        .dsc-button-toggle-group {
            margin: 0.5em;
        }

        .dsc-button-toggle-group > :not(:last-of-type) {
            margin-bottom: 0.25em;
        }

        .dsc-button-toggle {
            height: 56px;
            width: 56px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }
`;

const sidebarContentStyle = css`
    padding: 0 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .dsc-tab-bar__sidebar-header {
        display: flex;
        justify-content: space-between;
    }
`;

const sidebarContentContainerStyle = css`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

function getSidebarStyle(isOpen: boolean) {
    return css`
        border: 1px solid ${cvar('inactiveBorderColor')};
        height: 100%;
        background-color: white;
        box-shadow: ${isOpen ? '0 4px 6px 2px rgba(0,0,0,0.1);' : 'none'};
    `;
}

const contentTabTitleStyle = css`
    font-size: 9px;
`;

interface TabbedSideBarIcons extends IconsProp {
    close: {
        icon: string;
    };
}
export interface TabbedSideBarProps {
    tabs: ContentTab[];
    activeTabId?: string;
    tabWidth?: number;
    position?: SidebarPosition;
    onTabSelect: (tabId?: string) => void;
    onClose: () => void;
    icons?: TabbedSideBarIcons;
    isButton?: boolean;
}

export function TabbedSideBar({
    tabs,
    activeTabId,
    tabWidth = 376,
    position = 'left',
    onTabSelect,
    onClose,
    icons,
    isButton = true,
}: TabbedSideBarProps) {
    const { CLOSE } = useIcons();
    const selectedTab = tabs.find((tab) => tab.id === activeTabId);
    const Tab = selectedTab?.Tab;
    const tabProps = selectedTab?.tabProps;
    const showSidebar = !!Tab;

    const contentHeader = (
        <div className="dsc-tab-bar__sidebar-header">
            <H2>{selectedTab?.title}</H2>
            <IconButton
                onClick={onClose}
                isHoverEffectNeeded={false}
                size={IconSizes.Small}
                data-testid="dsc-tab-bar__sidebar-close"
            >
                <Icon content={icons?.close.icon ?? CLOSE.icon} />
            </IconButton>
        </div>
    );

    return (
        <div className={cx('dsc-tab-bar', getTabBarStyle(position))}>
            <div className="dsc-tab-bar__sidebar">
                <Sidebar
                    className={getSidebarStyle(showSidebar)}
                    isOpen={showSidebar}
                    width={tabWidth}
                    position={position}
                >
                    <div className={cx('dsc-tab-bar__sidebar-content', sidebarContentStyle)}>
                        {contentHeader}
                        <div className={cx('dsc-tab-bar__sidebar-content-container', sidebarContentContainerStyle)}>
                            {Tab ? <Tab {...(tabProps ?? {})} /> : null}
                        </div>
                    </div>
                </Sidebar>
            </div>
            {isButton && (
                <div className="dsc-tab-bar__tab-group">
                    {tabs.length ? (
                        <ButtonToggleGroup
                            value={activeTabId ?? undefined}
                            onChange={(value: string) => onTabSelect(value)}
                            orientation="vertical"
                        >
                            {tabs.map((contentTab) => (
                                <ButtonToggle
                                    key={contentTab.id}
                                    id={contentTab.id}
                                    className={`dsc-tab-${contentTab.id}-button`}
                                    onClick={contentTab.onClick}
                                >
                                    <Icon content={contentTab.icon} />
                                    <div className={contentTabTitleStyle}>{contentTab.title}</div>
                                </ButtonToggle>
                            ))}
                        </ButtonToggleGroup>
                    ) : null}
                </div>
            )}
        </div>
    );
}
