import React from 'react';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import {
    ICON_BOX_HEIGHT,
    ICON_BOX_MOBILE_HEIGHT,
    ICON_BOX_MOBILE_WIDTH,
    ICON_BOX_WIDTH,
    ICON_GALLERY_DEFAULT_ICON_SIZE,
} from './constants';
import { IconBox } from './IconBox';
import { IconGalleryWrapper } from './IconGalleryWrapper';
import type { IconGalleryProps } from './types';

export const IconGallery = ({
    data,
    className,
    header,
    tooltipDescription,
    onClick,
    iconSize = ICON_GALLERY_DEFAULT_ICON_SIZE,
    width,
    height,
    selectedIcon,
}: IconGalleryProps) => {
    const { isSmallDevice } = useBreakpointInfo();
    const widthValue = width || (isSmallDevice ? ICON_BOX_MOBILE_WIDTH : ICON_BOX_WIDTH);
    const heightValue = height || (isSmallDevice ? ICON_BOX_MOBILE_HEIGHT : ICON_BOX_HEIGHT);

    return (
        <IconGalleryWrapper label={header || ''} infoIconTooltip={tooltipDescription} className={className}>
            {data.map(({ id, label, icon }) => (
                <IconBox
                    id={id}
                    key={label}
                    icon={icon}
                    label={label}
                    iconSize={iconSize}
                    width={widthValue}
                    height={heightValue}
                    isActive={selectedIcon === id}
                    onClick={(shapeId: string) => {
                        onClick?.(shapeId);
                    }}
                />
            ))}
        </IconGalleryWrapper>
    );
};
