import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { RenderPhoto, RenderRowContainer } from 'react-photo-album';
import { PhotoAlbum } from 'react-photo-album';

import { cx } from '@emotion/css';

import { useBreakpointInfo } from '@design-stack-ct/utility-react';

import { Draggable, DraggableItem, IconButton, Spinner, useDraggingState } from '@dexter/dex-component-library';
import { Icon, useIcons } from '@dexter/dex-icon-library';

import ArrowIcon from './ArrowIcon';
import {
    backContainerStyle,
    borderStyle,
    containerElementStyle,
    containerStyle,
    contentWrapperStyle,
    ellipsisStyle,
    iconStyle,
    processingTextStyle,
    subTitleStyle,
} from './GalleryCss';
import { ImageContent } from './ImageContent';
import { ImageContentFixed } from './ImageContentFixed';
import { ImageThumbnail } from './ImageThumbnail';
import { ImageThumbnailWrapper } from './ImageThumbnailWrapper';
import InfiniteScroll from './infiniteScroll';
import {
    DYNAMIC,
    FIXED,
    GALLERY_CONTAINER_HEIGHT_DESKTOP,
    GALLERY_CONTAINER_HEIGHT_MOBILE,
    HORIZONTAL,
    MAX_GALLERY_PHOTO,
    MIN_GALLERY_PHOTO,
    VERTICAL,
} from '../constants';
import { getInfoFromImage, isMultiPageAsset, isPercentProgress } from '../helpers/utils';
import { enUS } from '../localization';
import { GalleryContextProvider } from '../provider';
import type { ArrowsState, Asset, GalleryProps, Images, MultiPageAsset, ThumbnailButtonConfig } from '../types';

export const Gallery = ({
    images,
    containerHeight,
    fetchImagesOnScroll,
    onRemove,
    onAdd,
    icons,
    layout,
    allImagesLoaded,
    showInfo,
    localization = enUS,
    type = 'horizontal',
    onScrollCallback,
    loaderData,
    setViewAll,
    setSelectedPdfAsset,
    selectedPdfAsset,
    showPdfPagesInViewAll,
    onSelectEnsemble,
    onModalEnsembleClick,
    noResult = { status: false, content: null },
    error = { status: false, content: null },
    restGalleryProps,
    selectedAssetId,
    setSelectedAssetId,
    showThumbnailLoader,
    isOverlayNeeded = true,
    isTitleNeeded = false,
}: GalleryProps) => {
    const [multiPageAsset, setMultiPageAsset] = useState<MultiPageAsset | null>(null);
    const { setIsDragging } = useDraggingState();
    const { isSmallDevice } = useBreakpointInfo();
    const { processingOverlay, allImages, pagesCountLabel, pageCountLabel } = localization;
    const { FOLDER, ARROW_LEFT, ARROW_RIGHT, PIXART_DELETE_EMPTY, PIXART_ADD } = useIcons();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const imageHorizontalRef = useRef<HTMLDivElement>(null);
    const backAllAssetRef = useRef<HTMLDivElement>(null);
    const [verticalScrollStart, setVerticalScrollStart] = useState(false);
    const [verticalScrollEnd, setVerticalScrollEnd] = useState(true);
    const galleryOuterDiv = useRef<HTMLDivElement>(null);
    const [lastClickedThumbnailId, setLastClickedThumbnailId] = useState<string>();
    const [firstClick, setFirstClick] = useState<boolean>(false);
    const [clickedAssetId, setClickedAssetId] = useState<string>();
    const [clickedPageNumber, setClickedPageNumber] = useState<number>();
    const [clickedTemplateId, setClickedTemplateId] = useState('');

    const minPhotos = MIN_GALLERY_PHOTO;
    let maxPhotos = MAX_GALLERY_PHOTO;

    const galleryContextValue = { localization, applyModalButtonClick: onModalEnsembleClick, onSelectEnsemble };

    const [arrows, setArrows] = useState<ArrowsState>({
        left: false,
        right: !allImagesLoaded,
    });
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const backHeight = backAllAssetRef.current?.offsetHeight ?? 0;

    const extractedIcon = icons.find((icon) => icon.position === 'BOTTOM_LEFT');
    const customCss = extractedIcon ? extractedIcon?.customCss : {};

    const deleteIconObject: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_DELETE_EMPTY,
            position: 'BOTTOM_LEFT',
            action: onRemove,
            customCss,
        },
    ];

    const addIconObject: ThumbnailButtonConfig[] = [
        {
            icon: PIXART_ADD,
            position: 'BOTTOM_RIGHT',
            action: onAdd,
        },
    ];

    const handleArrowClick = useCallback(
        (arrowType: string) => {
            const sectionContainer = scrollContainerRef.current;
            if (!sectionContainer) return;
            const containerWidth = sectionContainer.clientWidth || 0;
            const imagesPosition = sectionContainer.scrollLeft || 0;
            if (arrowType === 'left' && imagesPosition >= 0) {
                // Move images to the left
                sectionContainer.scrollBy({
                    left: -containerWidth,
                    behavior: 'smooth',
                });
            } else if (
                arrowType === 'right' &&
                imagesPosition + containerWidth <= (sectionContainer.scrollWidth || 0)
            ) {
                // Move images to the right
                sectionContainer.scrollBy({
                    left: containerWidth,
                    behavior: 'smooth',
                });
            }
        },
        [scrollContainerRef],
    );

    // show vertical gallery for multi pages file (eg: pdf)
    const handleMultiPageAsset = (assetId: string) => {
        const asset = images.find((image) => image.id === assetId);
        if (isMultiPageAsset(asset)) {
            if (!isSmallDevice) {
                setMultiPageAsset(asset);
            } else if (firstClick && clickedAssetId === assetId) {
                setMultiPageAsset(asset);
                setFirstClick(false);
                setClickedPageNumber(0);
            } else {
                setClickedAssetId?.(assetId);
                setFirstClick(true);
            }
        }
    };
    // back to the vertical gallery view
    const showAllAssets = () => {
        if (!selectedPdfAsset) {
            setMultiPageAsset(null);
        } else if (setViewAll !== undefined) setViewAll(false);
    };

    // to customize the layout structure of PhotoAlbum package for vertical gallery
    const renderRowContainer: RenderRowContainer = ({ rowContainerProps, children }) => (
        <div {...rowContainerProps} className="dex-gallery-react-photo-album--rows multiple-pages">
            {children}
        </div>
    );

    const firstImage = images[0];
    const proportion = (!isMultiPageAsset(firstImage) && (firstImage?.width || 0) / (firstImage?.height || 0)) || 1;
    if (!isMultiPageAsset(firstImage) && firstImage?.width && firstImage?.height) {
        if (proportion < 0.5) {
            maxPhotos = 3;
        } else if (proportion > 2) {
            maxPhotos = 1;
        }
    }

    // TODO: Add the correct type and revisit the code
    // render the custom layout for vertical gallery
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderPhoto: RenderPhoto<any> = ({ layout: photoLayout, layoutOptions, imageProps, photo }) => {
        let asset: Asset | undefined;
        let pageNumber: number | undefined;
        let draggableId: string | undefined;
        const { style } = imageProps;

        const percentage =
            restGalleryProps?.singleImageWidth && restGalleryProps?.singleImageWidth !== ''
                ? restGalleryProps?.singleImageWidth
                : '33.33%';
        const isMultiPage: boolean = photo?.key?.includes('_id_') || false;

        // TODO: Revisit this code
        if (isMultiPage) {
            asset = images.find((image) => image.id === photo.key?.split('_id_')[0]);
            if (typeof asset === 'undefined') return;
            pageNumber = isMultiPageAsset(asset) ? asset.pages[photoLayout.index].pageNumber : asset?.pageNumber;
            draggableId = isMultiPageAsset(asset) ? asset.pages[photoLayout.index].draggableId : asset?.draggableId;
        } else {
            asset = images[photoLayout.index];
            if (typeof asset === 'undefined') return;
            pageNumber = isMultiPageAsset(asset) ? 1 : asset.pageNumber;
            draggableId = isMultiPageAsset(asset) ? asset.pages[0].draggableId : asset.draggableId;
        }

        const dragData = {
            assetId: asset.id,
            pageNumber,
        };
        const hasDraggableContent = !isSmallDevice && draggableId && DraggableItem.Image;
        const info = getInfoFromImage(asset);

        const ImageThumbnailContent = (imageType?: string) => (
            <ImageThumbnailWrapper
                imageProps={imageProps}
                imageType={imageType}
                info={info}
                icons={icons}
                images={images}
                pageNumber={pageNumber}
                addIconObject={addIconObject}
                proportion={proportion}
                asset={asset}
                photo={photo}
                photoLayout={photoLayout}
                onAdd={onAdd}
                setClickedAssetId={setClickedAssetId}
                setClickedPageNumber={setClickedPageNumber}
                clickedPageNumber={clickedPageNumber}
                clickedAssetId={clickedAssetId}
                clickedTemplateId={clickedTemplateId}
                setClickedTemplateId={setClickedTemplateId}
                showThumbnailLoader={showThumbnailLoader}
                isOverlayNeeded={isOverlayNeeded}
            />
        );

        const DraggableContent = (imageType?: string): JSX.Element => (
            <div
                className={cx(
                    `${
                        imageType === 'multiPage'
                            ? 'dex-gallery-multiple-pages dex-gallery-draggable'
                            : 'dex-gallery-draggable'
                    }`,
                    // If default asset needs to be selected pass it as selectedAssetId
                    borderStyle(
                        clickedAssetId ? clickedAssetId === asset.id : selectedAssetId === asset.id,
                        isOverlayNeeded,
                    ),
                )}
                style={{
                    width: imageType === 'multiPage' || images.length < 2 ? percentage : style?.width,
                    padding: `${layoutOptions.padding - 2}px`,
                    paddingBottom: 0,
                }}
            >
                {hasDraggableContent && draggableId && (
                    <Draggable
                        dragContentType={restGalleryProps?.draggableItemsType || DraggableItem.Image} // We are using draggableItemsType from restGalleryProps which is passed to Gallery component from respective components because draggable was not working incase of clipArt on See All page
                        dragData={dragData}
                        id={draggableId}
                        enabled={true}
                        className={contentWrapperStyle}
                        onIsDragging={(isDragging) => setIsDragging(isDragging)}
                    >
                        {ImageThumbnailContent(imageType)}
                    </Draggable>
                )}
                {!hasDraggableContent && ImageThumbnailContent(imageType)}
                {isTitleNeeded && photo.title && (
                    <p className={subTitleStyle} id={draggableId}>
                        {photo.title}
                    </p>
                )}
            </div>
        );

        if (isMultiPage) {
            //  render vertical gallery when clicked on Multiple page file
            // eslint-disable-next-line consistent-return
            return DraggableContent('multiPage');
        }

        const { loadingState } = asset;
        const isLoading = isPercentProgress(loadingState) || loadingState === 'processing';

        if (isLoading) {
            // eslint-disable-next-line consistent-return
            return (
                <div
                    className={cx('dex-gallery-loadingElement', containerElementStyle(type === VERTICAL))}
                    style={{
                        width: images.length < 2 ? percentage : style?.width,
                        padding: `${layoutOptions.padding - 2}px`,
                        paddingBottom: 0,
                        aspectRatio: style?.aspectRatio,
                    }}
                >
                    <Spinner
                        size="38px"
                        thickness={3}
                        label={isPercentProgress(loadingState) ? `${loadingState.percentage}%` : ''}
                    />
                    {loadingState === 'processing' && <span className={processingTextStyle}>{processingOverlay}</span>}
                </div>
            );
        }

        // shows the multi page (eg: pdf) images
        if (isMultiPageAsset(asset)) {
            const assetId = asset.id;
            // eslint-disable-next-line consistent-return
            return (
                <div
                    className={cx('dex-gallery-containerElement', containerElementStyle(type === VERTICAL))}
                    onClick={() => {
                        handleMultiPageAsset(assetId);
                    }}
                    key={assetId}
                    style={{
                        width: images.length < 2 ? percentage : style?.width,
                        padding: `${layoutOptions.padding - 2}px`,
                        paddingBottom: 0,
                    }}
                >
                    <div className="dex-gallery-folder">
                        <Icon content={FOLDER.icon} size="medium" className={iconStyle} />
                        <div className={ellipsisStyle}>{asset.fileName}</div>
                        <div className={ellipsisStyle}>
                            {asset.pages.length === 1 ? pageCountLabel : pagesCountLabel}
                        </div>
                        {icons && (
                            <ImageThumbnail
                                id={assetId}
                                icons={deleteIconObject}
                                info={info}
                                clickedAssetId={clickedAssetId}
                            />
                        )}
                    </div>
                </div>
            );
        }
        // eslint-disable-next-line consistent-return
        return DraggableContent();
    };

    useEffect(() => {
        if (isMultiPageAsset(selectedPdfAsset!)) {
            setMultiPageAsset(selectedPdfAsset);
        }
    }, [selectedPdfAsset]);
    /**
     * handle left and right arrow visibility on scroll
     */
    useEffect(() => {
        let isScrolling: NodeJS.Timeout;
        const scrollContainer = scrollContainerRef.current;
        const handleScroll = () => {
            const newScrollPosition = scrollContainerRef.current?.scrollLeft || 0;
            if (newScrollPosition === 0) {
                setArrows((prev: ArrowsState) => ({ ...prev, left: false, right: true }));
            } else if (
                // When we reach the end of the horizontal scroll sometime the scrollLeft value is not exactly the same as the scrollWidth, so we need to add a small value (5px) to the scrollLeft value to check if we reach the end of the scroll or not
                Math.round(newScrollPosition) + Math.round(Number(scrollContainer?.clientWidth)) + 5 >=
                (scrollContainer?.scrollWidth || 0)
            ) {
                // get the next set of images when reaches to the end of the horizontal scroll
                if (allImagesLoaded) {
                    setArrows((prev: ArrowsState) => ({ ...prev, left: true, right: false }));
                }
                fetchImagesOnScroll('next');
            } else if (newScrollPosition >= 1) {
                setArrows((prev: ArrowsState) => ({ ...prev, left: true, right: true }));
            }
        };

        scrollContainer?.addEventListener('scroll', () => {
            clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                handleScroll();
            }, 100);
        });
        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
        };
        // we only want to run this effect once and if we are coming back from pdf view
        // or we want to update the arrows visibility when all the images are loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesLoaded, allImagesLoaded]);

    // set horizontal arrows visibility if the horizontal scroll is available
    useEffect(() => {
        if (type === 'horizontal') {
            const scrollContainer = scrollContainerRef.current;
            if (scrollContainer) {
                const isScrolling = scrollContainer.offsetWidth < scrollContainer.scrollWidth;
                if (isScrolling) {
                    setArrows((prev: ArrowsState) => ({ ...prev, left: scrollContainer.scrollLeft > 0, right: true }));
                    setImagesLoaded(false);
                } else if (arrows.right) {
                    setArrows((prev: ArrowsState) => ({ ...prev, left: false, right: false }));
                }
            }
        }
        // we only want to run this effect once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handles top and bottom box-shadow of vertical gallery while scrolling
    useEffect(() => {
        const container = galleryOuterDiv.current;
        const handleScroll = () => {
            if (container && type === 'vertical') {
                // hide bottom shadow and show top shadow when scroll reaches to the bottom
                if (container.scrollHeight - Math.round(container.scrollTop) - 5 <= container.clientHeight) {
                    setVerticalScrollEnd(false);
                    setVerticalScrollStart(true);
                } else {
                    // show top and bottom shadow when scroll reaches to the middle
                    setVerticalScrollEnd(true);
                    setVerticalScrollStart(true);
                }
                // hide top shadow and show bottom shadow when scroll reaches to the top
                if (container.scrollTop === 0) {
                    setVerticalScrollStart(false);
                    setVerticalScrollEnd(true);
                }
            }
        };
        container?.addEventListener('scroll', handleScroll);
        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, [type, images]);

    useEffect(() => {
        const targetNode = galleryOuterDiv.current;
        const scrollableContainer = targetNode?.querySelector('.react-photo-album') as HTMLDivElement;
        if (type !== 'vertical' || !scrollableContainer) return () => {};

        const mutationObserver = new MutationObserver(() => {
            const isScrolling = (scrollableContainer?.clientHeight || 0) > (targetNode?.clientHeight || 0);

            // hide top shadow when vertical scroll reaches to the top
            if (targetNode?.scrollTop === 0) {
                setVerticalScrollStart(false);
            }

            // set the bottom shadow visibility based on the vertical scroll availability
            if (isScrolling) {
                setVerticalScrollEnd(true);
            } else {
                setVerticalScrollEnd(false);
            }
        });

        // Start observing the target node for configured mutations
        scrollableContainer && mutationObserver.observe(scrollableContainer, { childList: true });

        // Cleanup the observer when the component unmounts
        return () => {
            mutationObserver.disconnect();
        };
    }, [type, error.status, noResult.status]);

    return (
        <GalleryContextProvider context={galleryContextValue}>
            <div
                ref={imageHorizontalRef}
                className={cx(
                    'dex-gallery-container',
                    containerStyle(containerHeight, backHeight, type === VERTICAL, isOverlayNeeded),
                    type === HORIZONTAL ? `dex-gallery-container-${HORIZONTAL}` : `dex-gallery-container-${VERTICAL}`,
                )}
            >
                {type === VERTICAL && layout === FIXED && (
                    <>
                        {verticalScrollStart && <div className="dex-gallery-photo-store-content-fading-top"></div>}
                        <InfiniteScroll
                            dataLength={images.length}
                            next={() => fetchImagesOnScroll('next')}
                            hasMore={true}
                            scrollableTarget="dex-gallery-react-photo-album-outer-div"
                            parentTarget="dex-gallery-container"
                            loader={false}
                            scrollThreshold={0.9}
                            onScrollCallback={onScrollCallback}
                        >
                            <div className="dex-gallery-react-photo-album-outer-div" ref={galleryOuterDiv}>
                                <PhotoAlbum
                                    layout="rows"
                                    rowConstraints={{ minPhotos, maxPhotos }}
                                    photos={images.map((image: Images) => {
                                        if ((isMultiPageAsset(image) && image.pages) || image.loadingState) {
                                            return {
                                                key: image.id,
                                                src: '',
                                                height: image.height ?? 50,
                                                width: image.width ?? 50,
                                            };
                                        }
                                        return {
                                            src: image.thumbnailUrl ?? image.previewUrl ?? '',
                                            width: image.width ?? 50,
                                            height: image.height ?? 50,
                                            key: image.id,
                                            ensembles: image.ensembles ?? {},
                                            title: image.title ?? '',
                                            subTitle: image.subTitle ?? '',
                                        };
                                    })}
                                    renderPhoto={renderPhoto}
                                    targetRowHeight={
                                        isSmallDevice
                                            ? GALLERY_CONTAINER_HEIGHT_MOBILE
                                            : GALLERY_CONTAINER_HEIGHT_DESKTOP
                                    }
                                />
                                {loaderData?.status && (
                                    <div className="dex-gallery-image-loader">{loaderData?.content}</div>
                                )}
                            </div>
                        </InfiniteScroll>
                        {verticalScrollEnd && <div className="dex-gallery-photo-store-content-fading-bottom"></div>}
                    </>
                )}
                {type === VERTICAL && layout === DYNAMIC && (
                    <>
                        {Array.isArray(multiPageAsset?.pages) && (
                            <div ref={backAllAssetRef} className={cx('dex-gallery-back-container', backContainerStyle)}>
                                <IconButton onClick={showAllAssets} size="12px">
                                    <Icon content={ARROW_LEFT.icon} />
                                </IconButton>
                                {allImages}
                            </div>
                        )}
                        {noResult?.status && noResult.content}
                        {error?.status && error.content}
                        {verticalScrollStart && <div className="dex-gallery-photo-store-content-fading-top"></div>}
                        {multiPageAsset?.pages && (
                            <InfiniteScroll
                                dataLength={images.length}
                                next={() => fetchImagesOnScroll('next')}
                                hasMore={true}
                                scrollableTarget="dex-gallery-react-photo-album-outer-div"
                                parentTarget="dex-gallery-container"
                                loader={false}
                                scrollThreshold={0.8}
                            >
                                <div className="dex-gallery-react-photo-album-outer-div" ref={galleryOuterDiv}>
                                    <PhotoAlbum
                                        layout="rows"
                                        photos={multiPageAsset?.pages.map((image: Images) => ({
                                            src: image.thumbnailUrl || image.previewUrl || '',
                                            key: `${image.id}_id_${image.pageNumber}`,
                                            width: image.width ?? 50,
                                            height: image.height ?? 50,
                                        }))}
                                        renderPhoto={renderPhoto}
                                        renderRowContainer={renderRowContainer}
                                    />
                                </div>
                            </InfiniteScroll>
                        )}
                        {!multiPageAsset && noResult?.status === false && error?.status === false && (
                            <InfiniteScroll
                                dataLength={images.length}
                                next={() => fetchImagesOnScroll('next')}
                                hasMore={true}
                                scrollableTarget="dex-gallery-react-photo-album-outer-div"
                                parentTarget="dex-gallery-container"
                                loader={false}
                                scrollThreshold={0.9}
                                onScrollCallback={onScrollCallback}
                            >
                                <div className="dex-gallery-react-photo-album-outer-div" ref={galleryOuterDiv}>
                                    <PhotoAlbum
                                        layout="rows"
                                        photos={images.map((image: Images) => {
                                            if ((isMultiPageAsset(image) && image.pages) || image.loadingState) {
                                                return {
                                                    key: image.id,
                                                    src: '',
                                                    height: image.height ?? 50,
                                                    width: image.width ?? 50,
                                                    alt: image.title || '',
                                                };
                                            }

                                            return {
                                                src: image.thumbnailUrl ?? image.previewUrl ?? '',
                                                width: image.width ?? 50,
                                                height: image.height ?? 50,
                                                key: image.id,
                                                title: image.title || '',
                                                alt: image.title || '',
                                            };
                                        })}
                                        renderPhoto={renderPhoto}
                                        targetRowHeight={
                                            isSmallDevice
                                                ? GALLERY_CONTAINER_HEIGHT_MOBILE
                                                : GALLERY_CONTAINER_HEIGHT_DESKTOP
                                        }
                                        rowConstraints={{
                                            minPhotos: restGalleryProps?.minPhotos,
                                            maxPhotos: restGalleryProps?.maxPhotos,
                                        }}
                                    />
                                    {loaderData?.status && (
                                        <div className="dex-gallery-image-loader">{loaderData?.content}</div>
                                    )}
                                </div>
                            </InfiniteScroll>
                        )}
                        {verticalScrollEnd && <div className="dex-gallery-photo-store-content-fading-bottom"></div>}
                    </>
                )}
                {type === HORIZONTAL && (
                    <>
                        {arrows.left && scrollContainerRef.current?.scrollLeft !== 0 && (
                            <>
                                <div className="dex-gallery-photo-store-content-fading-left"></div>
                                <ArrowIcon
                                    className="dex-gallery-arrow dex-gallery-arrow-left"
                                    icon={ARROW_LEFT}
                                    onClick={() => handleArrowClick('left')}
                                />
                            </>
                        )}
                        {layout === DYNAMIC && (
                            <ImageContent
                                images={images}
                                scrollContainerRef={scrollContainerRef}
                                backAllAssetRef={backAllAssetRef}
                                imagesLoaded={setImagesLoaded}
                                onRemove={onRemove}
                                onAdd={onAdd}
                                icons={icons}
                                draggableItemType={restGalleryProps?.draggableItemsType ?? DraggableItem.Image}
                                showInfo={showInfo}
                                localization={localization}
                                type={type}
                                setViewAll={setViewAll}
                                setSelectedPdfAsset={setSelectedPdfAsset}
                                showPdfPagesInViewAll={showPdfPagesInViewAll}
                                containerHeight={containerHeight}
                                // TODO: optimize this condition when we don't receive the selectedAssetId
                                clickedAssetId={setSelectedAssetId ? selectedAssetId : lastClickedThumbnailId}
                                setClickedAssetId={setSelectedAssetId || setLastClickedThumbnailId}
                            />
                        )}
                        {layout === FIXED && (
                            <ImageContentFixed
                                images={images}
                                scrollContainerRef={scrollContainerRef}
                                backAllAssetRef={backAllAssetRef}
                                imagesLoaded={setImagesLoaded}
                                onRemove={onRemove}
                                onAdd={onAdd}
                                icons={icons}
                                showInfo={showInfo}
                                localization={localization}
                                clickedAssetId={selectedAssetId}
                                setClickedAssetId={setSelectedAssetId}
                            />
                        )}
                        {arrows.right && (
                            <>
                                <div className="dex-gallery-photo-store-content-fading-right"></div>
                                <ArrowIcon
                                    className="dex-gallery-arrow dex-gallery-arrow-right"
                                    icon={ARROW_RIGHT}
                                    onClick={() => handleArrowClick('right')}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </GalleryContextProvider>
    );
};
