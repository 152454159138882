import type { ReactNode } from 'react';

export enum CollapsibleIconAlignment {
    Left = 'Left',
    Right = 'Right',
}

export interface CollapsibleOptions {
    /** A boolean that determines if only one item can be open at the same time, only works when the component is uncontrolled */
    isAccordionMode?: boolean;
    /** Determines what icon will be displayed in the Collapsible.Item.Header */
    icon?: { open: string; close: string };
    /** Collection of Ids to be open by default in the Collapsible */
    defaultActiveIds?: string[];
    /** Determines the alignment of the icon displayed in the Collapsible.Item.Header */
    iconAlignment?: CollapsibleIconAlignment;
}
export interface CollapsibleProps {
    /**  ReactNode: `<Collapsible.Item>` */
    children: ReactNode;
    /** className that will be added to the root component */
    className?: string;
    /** Additional configuration */
    options?: CollapsibleOptions;
}
export interface CollapsibleItemProps {
    /** `<Collapsible.Item.Header>` + `<Collapsible.Item.Body>` */
    children: ReactNode;
    /** A className that will be added to the root element */
    className?: string;
    /** Determines the id that will be used to control the internal state, if "undefined" if will generate a unique id  */
    id?: string;
    /** Determines if collapsible item is open, if value is "undefined" it will use internal state to hide/show the content */
    isOpen?: boolean;
    /** A callback that is called when `<Collapsible.Item.Header>` is clicked, returns the id of the item  */
    onToggle?: (id: string) => void;
}

export interface CollapsibleHeaderProps {
    /** The content that will be rendered in the component */
    children: ReactNode;
    /** A className that will be added to the root element */
    className?: string;
}

export interface CollapsibleBodyProps {
    /** The content that will be rendered in the component */
    children: ReactNode;
    /** A className that will be added to the root element */
    className?: string;
}
